import { Container, Paper, Stack, Text, Title } from "@mantine/core"

export const PrivacyScreen = () => {
  return (
    <Container size="md" py="xl">
      <Paper p="xl" radius="md" withBorder={true}>
        <Stack>
          <Title order={1}>Privacy Policy</Title>

          <Text>Last updated: {new Date().toLocaleDateString()}</Text>

          <Title order={2}>1. Information We Collect</Title>
          <Text>
            To provide you with the best possible board game collection
            management experience, we collect: - Basic account information
            (email, username) - Your board game collection data - Wishlist
            information - Club/team memberships and preferences - Game night
            participation and voting data
          </Text>

          <Title order={2}>2. How We Use Your Information</Title>
          <Text>
            We use your information to: - Maintain and manage your digital board
            game collection - Enable sharing of collection information with your
            clubs/teams - Facilitate game night planning and voting - Improve
            our service and user experience - Send notifications about game
            nights and team activities (with your consent)
          </Text>

          <Title order={2}>3. Information Sharing</Title>
          <Text>
            - Your collection and wishlist can be shared with other users based
            on your privacy settings - Team/club members can see shared
            collection information as per team settings - We never sell your
            personal information to third parties - We may share anonymized,
            aggregated data for analytics purposes
          </Text>

          <Title order={2}>4. Data Security</Title>
          <Text>
            We implement appropriate security measures to protect your personal
            information and board game collection data. All data is encrypted in
            transit and at rest.
          </Text>

          <Title order={2}>5. Your Rights</Title>
          <Text>
            You have the right to: - Access your personal data - Update or
            correct your information - Delete your account and associated data -
            Control your sharing preferences - Opt-out of notifications
          </Text>

          <Title order={2}>6. Contact Us</Title>
          <Text>
            If you have any questions about our Privacy Policy or how we handle
            your data, please contact us.
          </Text>
        </Stack>
      </Paper>
    </Container>
  )
}

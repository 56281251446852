import {Alert, Button, Container, Paper, PasswordInput, Stack, Text, Title,} from "@mantine/core"
import {useChangePassword, useSignOut} from "@nhost/react"
import {IconAlertCircle, IconCheck} from "@tabler/icons-react"
import {useNavigate} from "@tanstack/react-router"
import {type FormEvent, useEffect, useState} from "react"

export const ResetPasswordScreen = () => {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [countdown, setCountdown] = useState(5)
  const navigate = useNavigate()
  const { changePassword, isLoading, isSuccess, isError, error } =
    useChangePassword()
  const signOut = useSignOut()

  useEffect(() => {
    if (!isSuccess) return

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer)
          navigate({ to: "/login" })
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [isSuccess, navigate])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await changePassword(password)
    if (isSuccess) {
      await signOut()
    }
  }

  if (isSuccess) {
    return (
      <Container size="xs">
        <Paper p="xl" radius="md" withBorder={true}>
          <Stack gap="md">
            <Alert
              icon={<IconCheck size={16} />}
              title="Success!"
              color="green"
            >
              Your password has been successfully reset.
            </Alert>
            <Text size="sm" c="dimmed" ta="center">
              Redirecting to login in {countdown} seconds...
            </Text>
          </Stack>
        </Paper>
      </Container>
    )
  }

  return (
    <Container size="xs">
      <Paper p="xl" radius="md" withBorder={true}>
        <form onSubmit={handleSubmit}>
          <Stack gap="md">
            <Title order={2}>Reset Your Password</Title>
            <Text size="sm" c="dimmed">
              Please enter your new password below.
            </Text>

            {isError && (
              <Alert icon={<IconAlertCircle size={16} />} color="red">
                {error?.message}
              </Alert>
            )}

            <PasswordInput
              label="New Password"
              placeholder="Enter your new password"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              required={true}
            />

            <PasswordInput
              label="Confirm Password"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.currentTarget.value)}
              required={true}
            />

            <Button type="submit" loading={isLoading} fullWidth={true}>
              Reset Password
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  )
}

import { Button } from "@mantine/core"
import { useRouter } from "@tanstack/react-router"
import Styles from "./styles.module.scss"

export const LoggedOutScreen = () => {
  const router = useRouter()

  return (
    <div className={Styles.loggedOutContainer}>
      <div className={Styles.header}>
        <h3>You are logged out!</h3>
      </div>
      <div className={Styles.content}>
        <Button onClick={() => router.navigate({ to: "/login" })}>
          Login again!
        </Button>
      </div>
    </div>
  )
}

import { Button, Card, Image, Loader, SimpleGrid, Text } from "@mantine/core"
import { motion } from "framer-motion"
import type { FC } from "react"
import image from "#assets/not-found.png"
import type { ISearchResults } from "./interfaces"
import Styles from "./styles.module.scss"

export const SearchResults: FC<ISearchResults> = ({
  isLoading,
  onSelect,
  results,
}) => {
  if (isLoading) {
    return (
      <div className={Styles.loading}>
        <Loader />
        <div>Loading...</div>
      </div>
    )
  }

  if (!results || results.length === 0) {
    return (
      <div className={Styles.noResults}>
        <Image src={image} />
        <div>No results found...</div>
      </div>
    )
  }

  return (
    <div className={Styles.container}>
      <SimpleGrid
        cols={{ base: 1, xs: 2, sm: 3, md: 4, lg: 5 }}
        spacing={{ base: "md", sm: "lg" }}
      >
        {results.map((result) => (
          <motion.div
            key={result?.id}
            whileHover={{
              scale: 1.05,
              transition: {
                damping: 20,
                duration: 0.2,
                stiffness: 500,
                type: "spring",
              },
            }}
          >
            <Card
              className={Styles.card}
              padding="lg"
              radius="md"
              shadow="sm"
              withBorder={true}
            >
              <Card.Section>
                <Image
                  alt={result?.name ?? ""}
                  fit="contain"
                  h={150}
                  src={result?.thumbnail_url}
                />
              </Card.Section>
              <Text lineClamp={2} size="md" ta="center">
                {result?.name}
              </Text>
              <Button
                color="blue"
                fullWidth={true}
                mt="md"
                onClick={() => onSelect(result?.id)}
                radius="md"
              >
                Add Game
              </Button>
            </Card>
          </motion.div>
        ))}
      </SimpleGrid>
    </div>
  )
}

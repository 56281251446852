import { Button, Container, Text, Title } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import classes from "./styles.module.scss"

interface IEmptyLibraryProps {
  onAddGame: () => void
  isOwner: boolean
}

export const EmptyLibrary = ({ onAddGame, isOwner }: IEmptyLibraryProps) => {
  return (
    <div className={classes.root}>
      <h3>
        {isOwner ? "Your board game library is empty" : "This library is empty"}
      </h3>
      <img src="https://res.cloudinary.com/govannon/image/upload/c_scale,w_360/v1738415740/bgl/empty_library_transparent.png" />
      <Text c="dimmed" size="lg">
        {isOwner
          ? "Start building your collection by adding your favorite board games. Track, organize, and share your gaming adventures!"
          : "This user hasn't added any board games to their library yet."}
      </Text>
      {isOwner && (
        <Button
          leftSection={<IconPlus size={20} />}
          size="md"
          mt="xl"
          onClick={onAddGame}
        >
          Add Your First Game
        </Button>
      )}
    </div>
  )
}

import { nhost } from "#lib/nhost"

export const fetchParameters = () => {
  const accessToken = nhost.auth.getAccessToken()
  if (!accessToken) {
    return {}
  }

  return {
    headers: {
      authorization: `Bearer ${nhost.auth.getAccessToken()}`,
    },
  }
}

import { AppShell } from "@mantine/core"
import type { FC, ReactElement, ReactNode } from "react"
import { Header } from "#components/Header"
import { SideNav } from "#components/SideNav"

interface ISidebarAndMain {
  mainContent: ReactElement
  sidebarContent?: ReactElement | ReactNode
}

export const TopNavWithSidebarAndMain: FC<ISidebarAndMain> = ({
  mainContent,
  sidebarContent,
}) => {
  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: "md",
        collapsed: { desktop: false, mobile: true },
      }}
      padding="md"
    >
      <Header />
      {sidebarContent && (
        <AppShell.Navbar>
          <SideNav>{sidebarContent}</SideNav>
        </AppShell.Navbar>
      )}
      <AppShell.Main style={{ display: "flex" }}>{mainContent}</AppShell.Main>
    </AppShell>
  )
}

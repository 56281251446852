import { Container, Paper, Stack, Text, Title } from "@mantine/core"

export const TosScreen = () => {
  return (
    <Container size="md" py="xl">
      <Paper p="xl" radius="md" withBorder={true}>
        <Stack>
          <Title order={1}>Terms of Service</Title>

          <Text>Last updated: {new Date().toLocaleDateString()}</Text>

          <Title order={2}>1. Service Description</Title>
          <Text>
            Our service provides a digital platform for managing your board game
            collection, creating wishlists, and organizing game nights with your
            friends and clubs. We offer tools to catalog your games, share your
            collection with others, and facilitate social gaming experiences.
          </Text>

          <Title order={2}>2. User Accounts</Title>
          <Text>
            To use our service, you must create an account with accurate
            information. You are responsible for maintaining the security of
            your account and for all activities that occur under your account.
            You must immediately notify us of any unauthorized use of your
            account.
          </Text>

          <Title order={2}>3. Collection Management</Title>
          <Text>
            Users are responsible for maintaining accurate information about
            their board game collections. While we strive to provide accurate
            game information, we cannot guarantee the completeness or accuracy
            of game data. Users should verify game details independently.
          </Text>

          <Title order={2}>4. Social Features and Clubs</Title>
          <Text>
            When using our social features and club functionality, you agree to:
            - Respect other users' privacy settings - Not misrepresent game
            ownership - Use the game night planning features responsibly -
            Follow any club rules established by club administrators - Not use
            the platform to harass or spam other users
          </Text>

          <Title order={2}>5. Content and Conduct</Title>
          <Text>
            You retain ownership of your content (comments, reviews, etc.) but
            grant us license to use it on the platform. You agree not to post
            inappropriate, offensive, or illegal content. We reserve the right
            to remove content or terminate accounts that violate these terms.
          </Text>

          <Title order={2}>6. Service Availability</Title>
          <Text>
            While we strive to maintain high availability, we do not guarantee
            uninterrupted access to the service. We may modify, suspend, or
            discontinue features with reasonable notice to users.
          </Text>

          <Title order={2}>7. Changes to Terms</Title>
          <Text>
            We may update these terms as our service evolves. We will notify
            users of significant changes. Continued use of the service after
            changes constitutes acceptance of the new terms.
          </Text>

          <Title order={2}>8. Contact Us</Title>
          <Text>
            If you have any questions about these Terms of Service or need to
            report violations, please contact us.
          </Text>
        </Stack>
      </Paper>
    </Container>
  )
}

// @ts-nocheck
import {fetchParameters} from "./graphqlFetchHeaders";
import {useMutation, UseMutationOptions, useQuery, UseQueryOptions,} from "@tanstack/react-query";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch("https://klptlepcyyukmlonwiyb.hasura.eu-central-1.nhost.run/v1/graphql", {
    method: "POST",
    ...(fetchParameters()),
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
}

export interface IAddGameOutput {
  __typename?: 'AddGameOutput';
  boardGameUuid?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface IBooleanComparisonExp {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface IIntComparisonExp {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface ILookupResult {
  __typename?: 'LookupResult';
  confidence?: Maybe<Scalars['Int']['output']>;
  data_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  page_url?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['String']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  update_url?: Maybe<Scalars['String']['output']>;
  version_status?: Maybe<Scalars['String']['output']>;
  versions?: Maybe<Array<Maybe<IVersions>>>;
}

export interface ILookupResults {
  __typename?: 'LookupResults';
  results?: Maybe<Array<Maybe<ILookupResult>>>;
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface IStringComparisonExp {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
}

export interface IVersions {
  __typename?: 'Versions';
  confidence?: Maybe<Scalars['Int']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  language_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['String']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  update_url?: Maybe<Scalars['String']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
}

/** columns and relationships of "always_true_view" */
export interface IAlwaysTrueView {
  __typename?: 'always_true_view';
  always_true?: Maybe<Scalars['Boolean']['output']>;
}

/** Boolean expression to filter rows from the table "always_true_view". All fields are combined with a logical 'AND'. */
export interface IAlwaysTrueViewBoolExp {
  _and?: InputMaybe<Array<IAlwaysTrueViewBoolExp>>;
  _not?: InputMaybe<IAlwaysTrueViewBoolExp>;
  _or?: InputMaybe<Array<IAlwaysTrueViewBoolExp>>;
  always_true?: InputMaybe<IBooleanComparisonExp>;
}

/** Ordering options when selecting data from "always_true_view". */
export interface IAlwaysTrueViewOrderBy {
  always_true?: InputMaybe<IOrderBy>;
}

/** select columns of table "always_true_view" */
export enum IAlwaysTrueViewSelectColumn {
  /** column name */
  ALWAYS_TRUE = 'always_true'
}

/** Streaming cursor of the table "always_true_view" */
export interface IAlwaysTrueViewStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IAlwaysTrueViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IAlwaysTrueViewStreamCursorValueInput {
  always_true?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum IAuthRefreshTokenTypesEnum {
  /** Personal access token */
  PAT = 'pat',
  /** Regular refresh token */
  REGULAR = 'regular'
}

/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
export interface IAuthRefreshTokenTypesEnumComparisonExp {
  _eq?: InputMaybe<IAuthRefreshTokenTypesEnum>;
  _in?: InputMaybe<Array<IAuthRefreshTokenTypesEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IAuthRefreshTokenTypesEnum>;
  _nin?: InputMaybe<Array<IAuthRefreshTokenTypesEnum>>;
}

/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface IAuthRefreshTokens {
  __typename?: 'authRefreshTokens';
  createdAt: Scalars['timestamptz']['output'];
  expiresAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  type: IAuthRefreshTokenTypesEnum;
  /** An object relationship */
  user: IUsers;
  userId: Scalars['uuid']['output'];
}


/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface IAuthRefreshTokensMetadataArgs {
  path?: InputMaybe<Scalars['String']['input']>;
}

/** order by aggregate values of table "auth.refresh_tokens" */
export interface IAuthRefreshTokensAggregateOrderBy {
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IAuthRefreshTokensMaxOrderBy>;
  min?: InputMaybe<IAuthRefreshTokensMinOrderBy>;
}

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export interface IAuthRefreshTokensBoolExp {
  _and?: InputMaybe<Array<IAuthRefreshTokensBoolExp>>;
  _not?: InputMaybe<IAuthRefreshTokensBoolExp>;
  _or?: InputMaybe<Array<IAuthRefreshTokensBoolExp>>;
  createdAt?: InputMaybe<ITimestamptzComparisonExp>;
  expiresAt?: InputMaybe<ITimestamptzComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  metadata?: InputMaybe<IJsonbComparisonExp>;
  type?: InputMaybe<IAuthRefreshTokenTypesEnumComparisonExp>;
  user?: InputMaybe<IUsersBoolExp>;
  userId?: InputMaybe<IUuidComparisonExp>;
}

/** order by max() on columns of table "auth.refresh_tokens" */
export interface IAuthRefreshTokensMaxOrderBy {
  createdAt?: InputMaybe<IOrderBy>;
  expiresAt?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  userId?: InputMaybe<IOrderBy>;
}

/** order by min() on columns of table "auth.refresh_tokens" */
export interface IAuthRefreshTokensMinOrderBy {
  createdAt?: InputMaybe<IOrderBy>;
  expiresAt?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  userId?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "auth.refresh_tokens" */
export interface IAuthRefreshTokensMutationResponse {
  __typename?: 'authRefreshTokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IAuthRefreshTokens>;
}

/** Ordering options when selecting data from "auth.refresh_tokens". */
export interface IAuthRefreshTokensOrderBy {
  createdAt?: InputMaybe<IOrderBy>;
  expiresAt?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  metadata?: InputMaybe<IOrderBy>;
  type?: InputMaybe<IOrderBy>;
  user?: InputMaybe<IUsersOrderBy>;
  userId?: InputMaybe<IOrderBy>;
}

/** select columns of table "auth.refresh_tokens" */
export enum IAuthRefreshTokensSelectColumn {
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  EXPIRESAT = 'expiresAt',
  /** column name */
  ID = 'id',
  /** column name */
  METADATA = 'metadata',
  /** column name */
  TYPE = 'type',
  /** column name */
  USERID = 'userId'
}

/** Streaming cursor of the table "authRefreshTokens" */
export interface IAuthRefreshTokensStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IAuthRefreshTokensStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IAuthRefreshTokensStreamCursorValueInput {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<IAuthRefreshTokenTypesEnum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface IBigintComparisonExp {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
}

/** columns and relationships of "board_games" */
export interface IBoardGames {
  __typename?: 'board_games';
  /** An array relationship */
  categories: Array<IBoardGamesCategories>;
  cloudinaryPublicId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  maxAge: Scalars['Int']['output'];
  maxPlayers: Scalars['Int']['output'];
  /** An array relationship */
  mechanics: Array<IBoardGamesMechanics>;
  minAge: Scalars['Int']['output'];
  minPlayers: Scalars['Int']['output'];
  nonRecommendedPlayerCount?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  owners: Array<IBoardGamesUser>;
  /** An aggregate relationship */
  owners_aggregate: IBoardGamesUserAggregate;
  playTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  recommendedPlayerCount?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  uuid: Scalars['uuid']['output'];
  yearPublished: Scalars['Int']['output'];
}


/** columns and relationships of "board_games" */
export interface IBoardGamesCategoriesArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesCategoriesOrderBy>>;
  where?: InputMaybe<IBoardGamesCategoriesBoolExp>;
}


/** columns and relationships of "board_games" */
export interface IBoardGamesMechanicsArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesMechanicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesMechanicsOrderBy>>;
  where?: InputMaybe<IBoardGamesMechanicsBoolExp>;
}


/** columns and relationships of "board_games" */
export interface IBoardGamesOwnersArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesUserOrderBy>>;
  where?: InputMaybe<IBoardGamesUserBoolExp>;
}


/** columns and relationships of "board_games" */
export interface IBoardGamesOwnersAggregateArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesUserOrderBy>>;
  where?: InputMaybe<IBoardGamesUserBoolExp>;
}

/** Boolean expression to filter rows from the table "board_games". All fields are combined with a logical 'AND'. */
export interface IBoardGamesBoolExp {
  _and?: InputMaybe<Array<IBoardGamesBoolExp>>;
  _not?: InputMaybe<IBoardGamesBoolExp>;
  _or?: InputMaybe<Array<IBoardGamesBoolExp>>;
  categories?: InputMaybe<IBoardGamesCategoriesBoolExp>;
  cloudinaryPublicId?: InputMaybe<IStringComparisonExp>;
  description?: InputMaybe<IStringComparisonExp>;
  imageUrl?: InputMaybe<IStringComparisonExp>;
  maxAge?: InputMaybe<IIntComparisonExp>;
  maxPlayers?: InputMaybe<IIntComparisonExp>;
  mechanics?: InputMaybe<IBoardGamesMechanicsBoolExp>;
  minAge?: InputMaybe<IIntComparisonExp>;
  minPlayers?: InputMaybe<IIntComparisonExp>;
  nonRecommendedPlayerCount?: InputMaybe<IIntComparisonExp>;
  owners?: InputMaybe<IBoardGamesUserBoolExp>;
  owners_aggregate?: InputMaybe<IBoardGamesUserAggregateBoolExp>;
  playTimeInMinutes?: InputMaybe<IIntComparisonExp>;
  recommendedPlayerCount?: InputMaybe<IIntComparisonExp>;
  slug?: InputMaybe<IStringComparisonExp>;
  summary?: InputMaybe<IStringComparisonExp>;
  thumbnailUrl?: InputMaybe<IStringComparisonExp>;
  title?: InputMaybe<IStringComparisonExp>;
  uuid?: InputMaybe<IUuidComparisonExp>;
  yearPublished?: InputMaybe<IIntComparisonExp>;
}

/** columns and relationships of "board_games_categories" */
export interface IBoardGamesCategories {
  __typename?: 'board_games_categories';
  /** An object relationship */
  board_game?: Maybe<IBoardGames>;
  board_game_uuid: Scalars['uuid']['output'];
  /** An object relationship */
  category?: Maybe<ICategories>;
  category_id: Scalars['Int']['output'];
}

/** order by aggregate values of table "board_games_categories" */
export interface IBoardGamesCategoriesAggregateOrderBy {
  avg?: InputMaybe<IBoardGamesCategoriesAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IBoardGamesCategoriesMaxOrderBy>;
  min?: InputMaybe<IBoardGamesCategoriesMinOrderBy>;
  stddev?: InputMaybe<IBoardGamesCategoriesStddevOrderBy>;
  stddev_pop?: InputMaybe<IBoardGamesCategoriesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IBoardGamesCategoriesStddevSampOrderBy>;
  sum?: InputMaybe<IBoardGamesCategoriesSumOrderBy>;
  var_pop?: InputMaybe<IBoardGamesCategoriesVarPopOrderBy>;
  var_samp?: InputMaybe<IBoardGamesCategoriesVarSampOrderBy>;
  variance?: InputMaybe<IBoardGamesCategoriesVarianceOrderBy>;
}

/** order by avg() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesAvgOrderBy {
  category_id?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "board_games_categories". All fields are combined with a logical 'AND'. */
export interface IBoardGamesCategoriesBoolExp {
  _and?: InputMaybe<Array<IBoardGamesCategoriesBoolExp>>;
  _not?: InputMaybe<IBoardGamesCategoriesBoolExp>;
  _or?: InputMaybe<Array<IBoardGamesCategoriesBoolExp>>;
  board_game?: InputMaybe<IBoardGamesBoolExp>;
  board_game_uuid?: InputMaybe<IUuidComparisonExp>;
  category?: InputMaybe<ICategoriesBoolExp>;
  category_id?: InputMaybe<IIntComparisonExp>;
}

/** order by max() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesMaxOrderBy {
  board_game_uuid?: InputMaybe<IOrderBy>;
  category_id?: InputMaybe<IOrderBy>;
}

/** order by min() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesMinOrderBy {
  board_game_uuid?: InputMaybe<IOrderBy>;
  category_id?: InputMaybe<IOrderBy>;
}

/** Ordering options when selecting data from "board_games_categories". */
export interface IBoardGamesCategoriesOrderBy {
  board_game?: InputMaybe<IBoardGamesOrderBy>;
  board_game_uuid?: InputMaybe<IOrderBy>;
  category?: InputMaybe<ICategoriesOrderBy>;
  category_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "board_games_categories" */
export enum IBoardGamesCategoriesSelectColumn {
  /** column name */
  BOARD_GAME_UUID = 'board_game_uuid',
  /** column name */
  CATEGORY_ID = 'category_id'
}

/** order by stddev() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesStddevOrderBy {
  category_id?: InputMaybe<IOrderBy>;
}

/** order by stddev_pop() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesStddevPopOrderBy {
  category_id?: InputMaybe<IOrderBy>;
}

/** order by stddev_samp() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesStddevSampOrderBy {
  category_id?: InputMaybe<IOrderBy>;
}

/** Streaming cursor of the table "board_games_categories" */
export interface IBoardGamesCategoriesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IBoardGamesCategoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IBoardGamesCategoriesStreamCursorValueInput {
  board_game_uuid?: InputMaybe<Scalars['uuid']['input']>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
}

/** order by sum() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesSumOrderBy {
  category_id?: InputMaybe<IOrderBy>;
}

/** order by var_pop() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesVarPopOrderBy {
  category_id?: InputMaybe<IOrderBy>;
}

/** order by var_samp() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesVarSampOrderBy {
  category_id?: InputMaybe<IOrderBy>;
}

/** order by variance() on columns of table "board_games_categories" */
export interface IBoardGamesCategoriesVarianceOrderBy {
  category_id?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "board_games_mechanics" */
export interface IBoardGamesMechanics {
  __typename?: 'board_games_mechanics';
  /** An object relationship */
  board_game?: Maybe<IBoardGames>;
  board_game_uuid: Scalars['uuid']['output'];
  /** An object relationship */
  mechanic: IMechanics;
  mechanic_id: Scalars['Int']['output'];
}

/** order by aggregate values of table "board_games_mechanics" */
export interface IBoardGamesMechanicsAggregateOrderBy {
  avg?: InputMaybe<IBoardGamesMechanicsAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IBoardGamesMechanicsMaxOrderBy>;
  min?: InputMaybe<IBoardGamesMechanicsMinOrderBy>;
  stddev?: InputMaybe<IBoardGamesMechanicsStddevOrderBy>;
  stddev_pop?: InputMaybe<IBoardGamesMechanicsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IBoardGamesMechanicsStddevSampOrderBy>;
  sum?: InputMaybe<IBoardGamesMechanicsSumOrderBy>;
  var_pop?: InputMaybe<IBoardGamesMechanicsVarPopOrderBy>;
  var_samp?: InputMaybe<IBoardGamesMechanicsVarSampOrderBy>;
  variance?: InputMaybe<IBoardGamesMechanicsVarianceOrderBy>;
}

/** order by avg() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsAvgOrderBy {
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "board_games_mechanics". All fields are combined with a logical 'AND'. */
export interface IBoardGamesMechanicsBoolExp {
  _and?: InputMaybe<Array<IBoardGamesMechanicsBoolExp>>;
  _not?: InputMaybe<IBoardGamesMechanicsBoolExp>;
  _or?: InputMaybe<Array<IBoardGamesMechanicsBoolExp>>;
  board_game?: InputMaybe<IBoardGamesBoolExp>;
  board_game_uuid?: InputMaybe<IUuidComparisonExp>;
  mechanic?: InputMaybe<IMechanicsBoolExp>;
  mechanic_id?: InputMaybe<IIntComparisonExp>;
}

/** order by max() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsMaxOrderBy {
  board_game_uuid?: InputMaybe<IOrderBy>;
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** order by min() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsMinOrderBy {
  board_game_uuid?: InputMaybe<IOrderBy>;
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** Ordering options when selecting data from "board_games_mechanics". */
export interface IBoardGamesMechanicsOrderBy {
  board_game?: InputMaybe<IBoardGamesOrderBy>;
  board_game_uuid?: InputMaybe<IOrderBy>;
  mechanic?: InputMaybe<IMechanicsOrderBy>;
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** select columns of table "board_games_mechanics" */
export enum IBoardGamesMechanicsSelectColumn {
  /** column name */
  BOARD_GAME_UUID = 'board_game_uuid',
  /** column name */
  MECHANIC_ID = 'mechanic_id'
}

/** order by stddev() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsStddevOrderBy {
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** order by stddev_pop() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsStddevPopOrderBy {
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** order by stddev_samp() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsStddevSampOrderBy {
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** Streaming cursor of the table "board_games_mechanics" */
export interface IBoardGamesMechanicsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IBoardGamesMechanicsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IBoardGamesMechanicsStreamCursorValueInput {
  board_game_uuid?: InputMaybe<Scalars['uuid']['input']>;
  mechanic_id?: InputMaybe<Scalars['Int']['input']>;
}

/** order by sum() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsSumOrderBy {
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** order by var_pop() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsVarPopOrderBy {
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** order by var_samp() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsVarSampOrderBy {
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** order by variance() on columns of table "board_games_mechanics" */
export interface IBoardGamesMechanicsVarianceOrderBy {
  mechanic_id?: InputMaybe<IOrderBy>;
}

/** Ordering options when selecting data from "board_games". */
export interface IBoardGamesOrderBy {
  categories_aggregate?: InputMaybe<IBoardGamesCategoriesAggregateOrderBy>;
  cloudinaryPublicId?: InputMaybe<IOrderBy>;
  description?: InputMaybe<IOrderBy>;
  imageUrl?: InputMaybe<IOrderBy>;
  maxAge?: InputMaybe<IOrderBy>;
  maxPlayers?: InputMaybe<IOrderBy>;
  mechanics_aggregate?: InputMaybe<IBoardGamesMechanicsAggregateOrderBy>;
  minAge?: InputMaybe<IOrderBy>;
  minPlayers?: InputMaybe<IOrderBy>;
  nonRecommendedPlayerCount?: InputMaybe<IOrderBy>;
  owners_aggregate?: InputMaybe<IBoardGamesUserAggregateOrderBy>;
  playTimeInMinutes?: InputMaybe<IOrderBy>;
  recommendedPlayerCount?: InputMaybe<IOrderBy>;
  slug?: InputMaybe<IOrderBy>;
  summary?: InputMaybe<IOrderBy>;
  thumbnailUrl?: InputMaybe<IOrderBy>;
  title?: InputMaybe<IOrderBy>;
  uuid?: InputMaybe<IOrderBy>;
  yearPublished?: InputMaybe<IOrderBy>;
}

/** select columns of table "board_games" */
export enum IBoardGamesSelectColumn {
  /** column name */
  CLOUDINARYPUBLICID = 'cloudinaryPublicId',
  /** column name */
  DESCRIPTION = 'description',
  /** column name */
  IMAGEURL = 'imageUrl',
  /** column name */
  MAXAGE = 'maxAge',
  /** column name */
  MAXPLAYERS = 'maxPlayers',
  /** column name */
  MINAGE = 'minAge',
  /** column name */
  MINPLAYERS = 'minPlayers',
  /** column name */
  NONRECOMMENDEDPLAYERCOUNT = 'nonRecommendedPlayerCount',
  /** column name */
  PLAYTIMEINMINUTES = 'playTimeInMinutes',
  /** column name */
  RECOMMENDEDPLAYERCOUNT = 'recommendedPlayerCount',
  /** column name */
  SLUG = 'slug',
  /** column name */
  SUMMARY = 'summary',
  /** column name */
  THUMBNAILURL = 'thumbnailUrl',
  /** column name */
  TITLE = 'title',
  /** column name */
  UUID = 'uuid',
  /** column name */
  YEARPUBLISHED = 'yearPublished'
}

/** Streaming cursor of the table "board_games" */
export interface IBoardGamesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IBoardGamesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IBoardGamesStreamCursorValueInput {
  cloudinaryPublicId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxPlayers?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minPlayers?: InputMaybe<Scalars['Int']['input']>;
  nonRecommendedPlayerCount?: InputMaybe<Scalars['Int']['input']>;
  playTimeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  recommendedPlayerCount?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
  thumbnailUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['uuid']['input']>;
  yearPublished?: InputMaybe<Scalars['Int']['input']>;
}

/** columns and relationships of "board_games_user" */
export interface IBoardGamesUser {
  __typename?: 'board_games_user';
  /** An object relationship */
  boardGame: IBoardGames;
  boardGameUuid: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  owner?: Maybe<IUsers>;
  rating: Scalars['numeric']['output'];
  userUuid: Scalars['uuid']['output'];
  uuid: Scalars['uuid']['output'];
}

/** aggregated selection of "board_games_user" */
export interface IBoardGamesUserAggregate {
  __typename?: 'board_games_user_aggregate';
  aggregate?: Maybe<IBoardGamesUserAggregateFields>;
  nodes: Array<IBoardGamesUser>;
}

export interface IBoardGamesUserAggregateBoolExp {
  count?: InputMaybe<IBoardGamesUserAggregateBoolExpCount>;
}

export interface IBoardGamesUserAggregateBoolExpCount {
  arguments?: InputMaybe<Array<IBoardGamesUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IBoardGamesUserBoolExp>;
  predicate: IIntComparisonExp;
}

/** aggregate fields of "board_games_user" */
export interface IBoardGamesUserAggregateFields {
  __typename?: 'board_games_user_aggregate_fields';
  avg?: Maybe<IBoardGamesUserAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IBoardGamesUserMaxFields>;
  min?: Maybe<IBoardGamesUserMinFields>;
  stddev?: Maybe<IBoardGamesUserStddevFields>;
  stddev_pop?: Maybe<IBoardGamesUserStddevPopFields>;
  stddev_samp?: Maybe<IBoardGamesUserStddevSampFields>;
  sum?: Maybe<IBoardGamesUserSumFields>;
  var_pop?: Maybe<IBoardGamesUserVarPopFields>;
  var_samp?: Maybe<IBoardGamesUserVarSampFields>;
  variance?: Maybe<IBoardGamesUserVarianceFields>;
}


/** aggregate fields of "board_games_user" */
export interface IBoardGamesUserAggregateFieldsCountArgs {
  columns?: InputMaybe<Array<IBoardGamesUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
}

/** order by aggregate values of table "board_games_user" */
export interface IBoardGamesUserAggregateOrderBy {
  avg?: InputMaybe<IBoardGamesUserAvgOrderBy>;
  count?: InputMaybe<IOrderBy>;
  max?: InputMaybe<IBoardGamesUserMaxOrderBy>;
  min?: InputMaybe<IBoardGamesUserMinOrderBy>;
  stddev?: InputMaybe<IBoardGamesUserStddevOrderBy>;
  stddev_pop?: InputMaybe<IBoardGamesUserStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IBoardGamesUserStddevSampOrderBy>;
  sum?: InputMaybe<IBoardGamesUserSumOrderBy>;
  var_pop?: InputMaybe<IBoardGamesUserVarPopOrderBy>;
  var_samp?: InputMaybe<IBoardGamesUserVarSampOrderBy>;
  variance?: InputMaybe<IBoardGamesUserVarianceOrderBy>;
}

/** aggregate avg on columns */
export interface IBoardGamesUserAvgFields {
  __typename?: 'board_games_user_avg_fields';
  rating?: Maybe<Scalars['Float']['output']>;
}

/** order by avg() on columns of table "board_games_user" */
export interface IBoardGamesUserAvgOrderBy {
  rating?: InputMaybe<IOrderBy>;
}

/** Boolean expression to filter rows from the table "board_games_user". All fields are combined with a logical 'AND'. */
export interface IBoardGamesUserBoolExp {
  _and?: InputMaybe<Array<IBoardGamesUserBoolExp>>;
  _not?: InputMaybe<IBoardGamesUserBoolExp>;
  _or?: InputMaybe<Array<IBoardGamesUserBoolExp>>;
  boardGame?: InputMaybe<IBoardGamesBoolExp>;
  boardGameUuid?: InputMaybe<IUuidComparisonExp>;
  createdAt?: InputMaybe<ITimestamptzComparisonExp>;
  owner?: InputMaybe<IUsersBoolExp>;
  rating?: InputMaybe<INumericComparisonExp>;
  userUuid?: InputMaybe<IUuidComparisonExp>;
  uuid?: InputMaybe<IUuidComparisonExp>;
}

/** unique or primary key constraints on table "board_games_user" */
export enum IBoardGamesUserConstraint {
  /** unique or primary key constraint on columns "uuid" */
  BOARD_GAMES_USER_PKEY = 'board_games_user_pkey',
  /** unique or primary key constraint on columns "board_game_uuid", "user_uuid" */
  BOARD_GAMES_USER_USER_UUID_BOARDGAME_UUID_KEY = 'board_games_user_user_uuid_boardgame_uuid_key'
}

/** input type for incrementing numeric columns in table "board_games_user" */
export interface IBoardGamesUserIncInput {
  rating?: InputMaybe<Scalars['numeric']['input']>;
}

/** input type for inserting data into table "board_games_user" */
export interface IBoardGamesUserInsertInput {
  boardGameUuid?: InputMaybe<Scalars['uuid']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
}

/** aggregate max on columns */
export interface IBoardGamesUserMaxFields {
  __typename?: 'board_games_user_max_fields';
  boardGameUuid?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  userUuid?: Maybe<Scalars['uuid']['output']>;
  uuid?: Maybe<Scalars['uuid']['output']>;
}

/** order by max() on columns of table "board_games_user" */
export interface IBoardGamesUserMaxOrderBy {
  boardGameUuid?: InputMaybe<IOrderBy>;
  createdAt?: InputMaybe<IOrderBy>;
  rating?: InputMaybe<IOrderBy>;
  userUuid?: InputMaybe<IOrderBy>;
  uuid?: InputMaybe<IOrderBy>;
}

/** aggregate min on columns */
export interface IBoardGamesUserMinFields {
  __typename?: 'board_games_user_min_fields';
  boardGameUuid?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  rating?: Maybe<Scalars['numeric']['output']>;
  userUuid?: Maybe<Scalars['uuid']['output']>;
  uuid?: Maybe<Scalars['uuid']['output']>;
}

/** order by min() on columns of table "board_games_user" */
export interface IBoardGamesUserMinOrderBy {
  boardGameUuid?: InputMaybe<IOrderBy>;
  createdAt?: InputMaybe<IOrderBy>;
  rating?: InputMaybe<IOrderBy>;
  userUuid?: InputMaybe<IOrderBy>;
  uuid?: InputMaybe<IOrderBy>;
}

/** response of any mutation on the table "board_games_user" */
export interface IBoardGamesUserMutationResponse {
  __typename?: 'board_games_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IBoardGamesUser>;
}

/** on_conflict condition type for table "board_games_user" */
export interface IBoardGamesUserOnConflict {
  constraint: IBoardGamesUserConstraint;
  update_columns?: Array<IBoardGamesUserUpdateColumn>;
  where?: InputMaybe<IBoardGamesUserBoolExp>;
}

/** Ordering options when selecting data from "board_games_user". */
export interface IBoardGamesUserOrderBy {
  boardGame?: InputMaybe<IBoardGamesOrderBy>;
  boardGameUuid?: InputMaybe<IOrderBy>;
  createdAt?: InputMaybe<IOrderBy>;
  owner?: InputMaybe<IUsersOrderBy>;
  rating?: InputMaybe<IOrderBy>;
  userUuid?: InputMaybe<IOrderBy>;
  uuid?: InputMaybe<IOrderBy>;
}

/** primary key columns input for table: board_games_user */
export interface IBoardGamesUserPkColumnsInput {
  uuid: Scalars['uuid']['input'];
}

/** select columns of table "board_games_user" */
export enum IBoardGamesUserSelectColumn {
  /** column name */
  BOARDGAMEUUID = 'boardGameUuid',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  RATING = 'rating',
  /** column name */
  USERUUID = 'userUuid',
  /** column name */
  UUID = 'uuid'
}

/** input type for updating data in table "board_games_user" */
export interface IBoardGamesUserSetInput {
  boardGameUuid?: InputMaybe<Scalars['uuid']['input']>;
  rating?: InputMaybe<Scalars['numeric']['input']>;
}

/** aggregate stddev on columns */
export interface IBoardGamesUserStddevFields {
  __typename?: 'board_games_user_stddev_fields';
  rating?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev() on columns of table "board_games_user" */
export interface IBoardGamesUserStddevOrderBy {
  rating?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_pop on columns */
export interface IBoardGamesUserStddevPopFields {
  __typename?: 'board_games_user_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_pop() on columns of table "board_games_user" */
export interface IBoardGamesUserStddevPopOrderBy {
  rating?: InputMaybe<IOrderBy>;
}

/** aggregate stddev_samp on columns */
export interface IBoardGamesUserStddevSampFields {
  __typename?: 'board_games_user_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']['output']>;
}

/** order by stddev_samp() on columns of table "board_games_user" */
export interface IBoardGamesUserStddevSampOrderBy {
  rating?: InputMaybe<IOrderBy>;
}

/** aggregate sum on columns */
export interface IBoardGamesUserSumFields {
  __typename?: 'board_games_user_sum_fields';
  rating?: Maybe<Scalars['numeric']['output']>;
}

/** order by sum() on columns of table "board_games_user" */
export interface IBoardGamesUserSumOrderBy {
  rating?: InputMaybe<IOrderBy>;
}

/** update columns of table "board_games_user" */
export enum IBoardGamesUserUpdateColumn {
  /** column name */
  BOARDGAMEUUID = 'boardGameUuid',
  /** column name */
  RATING = 'rating'
}

export interface IBoardGamesUserUpdates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IBoardGamesUserIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IBoardGamesUserSetInput>;
  /** filter the rows which have to be updated */
  where: IBoardGamesUserBoolExp;
}

/** aggregate var_pop on columns */
export interface IBoardGamesUserVarPopFields {
  __typename?: 'board_games_user_var_pop_fields';
  rating?: Maybe<Scalars['Float']['output']>;
}

/** order by var_pop() on columns of table "board_games_user" */
export interface IBoardGamesUserVarPopOrderBy {
  rating?: InputMaybe<IOrderBy>;
}

/** aggregate var_samp on columns */
export interface IBoardGamesUserVarSampFields {
  __typename?: 'board_games_user_var_samp_fields';
  rating?: Maybe<Scalars['Float']['output']>;
}

/** order by var_samp() on columns of table "board_games_user" */
export interface IBoardGamesUserVarSampOrderBy {
  rating?: InputMaybe<IOrderBy>;
}

/** aggregate variance on columns */
export interface IBoardGamesUserVarianceFields {
  __typename?: 'board_games_user_variance_fields';
  rating?: Maybe<Scalars['Float']['output']>;
}

/** order by variance() on columns of table "board_games_user" */
export interface IBoardGamesUserVarianceOrderBy {
  rating?: InputMaybe<IOrderBy>;
}

/** columns and relationships of "categories" */
export interface ICategories {
  __typename?: 'categories';
  /** An array relationship */
  board_games: Array<IBoardGamesCategories>;
  value: Scalars['String']['output'];
}


/** columns and relationships of "categories" */
export interface ICategoriesBoardGamesArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesCategoriesOrderBy>>;
  where?: InputMaybe<IBoardGamesCategoriesBoolExp>;
}

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export interface ICategoriesBoolExp {
  _and?: InputMaybe<Array<ICategoriesBoolExp>>;
  _not?: InputMaybe<ICategoriesBoolExp>;
  _or?: InputMaybe<Array<ICategoriesBoolExp>>;
  board_games?: InputMaybe<IBoardGamesCategoriesBoolExp>;
  value?: InputMaybe<IStringComparisonExp>;
}

/** Ordering options when selecting data from "categories". */
export interface ICategoriesOrderBy {
  board_games_aggregate?: InputMaybe<IBoardGamesCategoriesAggregateOrderBy>;
  value?: InputMaybe<IOrderBy>;
}

/** select columns of table "categories" */
export enum ICategoriesSelectColumn {
  /** column name */
  VALUE = 'value'
}

/** Streaming cursor of the table "categories" */
export interface ICategoriesStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: ICategoriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface ICategoriesStreamCursorValueInput {
  value?: InputMaybe<Scalars['String']['input']>;
}

/** ordering argument of a cursor */
export enum ICursorOrdering {
  /** ascending ordering of the cursor */
  ASC = 'ASC',
  /** descending ordering of the cursor */
  DESC = 'DESC'
}

export interface IGetMechanicPercentagesForUserArgs {
  percentagethreshold?: InputMaybe<Scalars['numeric']['input']>;
  useruuid?: InputMaybe<Scalars['uuid']['input']>;
}

export interface IJsonbCastExp {
  String?: InputMaybe<IStringComparisonExp>;
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface IJsonbComparisonExp {
  _cast?: InputMaybe<IJsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
}

/** columns and relationships of "mechanic_percentage" */
export interface IMechanicPercentage {
  __typename?: 'mechanic_percentage';
  count?: Maybe<Scalars['bigint']['output']>;
  mechanic?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['numeric']['output']>;
}

/** Boolean expression to filter rows from the table "mechanic_percentage". All fields are combined with a logical 'AND'. */
export interface IMechanicPercentageBoolExp {
  _and?: InputMaybe<Array<IMechanicPercentageBoolExp>>;
  _not?: InputMaybe<IMechanicPercentageBoolExp>;
  _or?: InputMaybe<Array<IMechanicPercentageBoolExp>>;
  count?: InputMaybe<IBigintComparisonExp>;
  mechanic?: InputMaybe<IStringComparisonExp>;
  percentage?: InputMaybe<INumericComparisonExp>;
}

/** Ordering options when selecting data from "mechanic_percentage". */
export interface IMechanicPercentageOrderBy {
  count?: InputMaybe<IOrderBy>;
  mechanic?: InputMaybe<IOrderBy>;
  percentage?: InputMaybe<IOrderBy>;
}

/** select columns of table "mechanic_percentage" */
export enum IMechanicPercentageSelectColumn {
  /** column name */
  COUNT = 'count',
  /** column name */
  MECHANIC = 'mechanic',
  /** column name */
  PERCENTAGE = 'percentage'
}

/** Streaming cursor of the table "mechanic_percentage" */
export interface IMechanicPercentageStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IMechanicPercentageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IMechanicPercentageStreamCursorValueInput {
  count?: InputMaybe<Scalars['bigint']['input']>;
  mechanic?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['numeric']['input']>;
}

/** columns and relationships of "mechanics" */
export interface IMechanics {
  __typename?: 'mechanics';
  /** An array relationship */
  board_games: Array<IBoardGamesMechanics>;
  value: Scalars['String']['output'];
}


/** columns and relationships of "mechanics" */
export interface IMechanicsBoardGamesArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesMechanicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesMechanicsOrderBy>>;
  where?: InputMaybe<IBoardGamesMechanicsBoolExp>;
}

/** Boolean expression to filter rows from the table "mechanics". All fields are combined with a logical 'AND'. */
export interface IMechanicsBoolExp {
  _and?: InputMaybe<Array<IMechanicsBoolExp>>;
  _not?: InputMaybe<IMechanicsBoolExp>;
  _or?: InputMaybe<Array<IMechanicsBoolExp>>;
  board_games?: InputMaybe<IBoardGamesMechanicsBoolExp>;
  value?: InputMaybe<IStringComparisonExp>;
}

/** Ordering options when selecting data from "mechanics". */
export interface IMechanicsOrderBy {
  board_games_aggregate?: InputMaybe<IBoardGamesMechanicsAggregateOrderBy>;
  value?: InputMaybe<IOrderBy>;
}

/** select columns of table "mechanics" */
export enum IMechanicsSelectColumn {
  /** column name */
  VALUE = 'value'
}

/** Streaming cursor of the table "mechanics" */
export interface IMechanicsStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IMechanicsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IMechanicsStreamCursorValueInput {
  value?: InputMaybe<Scalars['String']['input']>;
}

/** mutation root */
export interface IMutationRoot {
  __typename?: 'mutation_root';
  addGame?: Maybe<IAddGameOutput>;
  /** delete single row from the table: "auth.refresh_tokens" */
  deleteAuthRefreshToken?: Maybe<IAuthRefreshTokens>;
  /** delete data from the table: "auth.refresh_tokens" */
  deleteAuthRefreshTokens?: Maybe<IAuthRefreshTokensMutationResponse>;
  /** delete single row from the table: "board_games_user" */
  deleteFromLibrary?: Maybe<IBoardGamesUser>;
  /** delete data from the table: "board_games_user" */
  delete_board_games_user?: Maybe<IBoardGamesUserMutationResponse>;
  /** insert a single row into the table: "board_games_user" */
  insertInLibrary?: Maybe<IBoardGamesUser>;
  /** insert data into the table: "board_games_user" */
  insert_board_games_user?: Maybe<IBoardGamesUserMutationResponse>;
  /** update data of the table: "board_games_user" */
  update_board_games_user?: Maybe<IBoardGamesUserMutationResponse>;
  /** update single row of the table: "board_games_user" */
  update_board_games_user_by_pk?: Maybe<IBoardGamesUser>;
  /** update multiples rows of table: "board_games_user" */
  update_board_games_user_many?: Maybe<Array<Maybe<IBoardGamesUserMutationResponse>>>;
}


/** mutation root */
export interface IMutationRootAddGameArgs {
  bggId: Scalars['Int']['input'];
}


/** mutation root */
export interface IMutationRootDeleteAuthRefreshTokenArgs {
  id: Scalars['uuid']['input'];
}


/** mutation root */
export interface IMutationRootDeleteAuthRefreshTokensArgs {
  where: IAuthRefreshTokensBoolExp;
}


/** mutation root */
export interface IMutationRootDeleteFromLibraryArgs {
  uuid: Scalars['uuid']['input'];
}


/** mutation root */
export interface IMutationRootDeleteBoardGamesUserArgs {
  where: IBoardGamesUserBoolExp;
}


/** mutation root */
export interface IMutationRootInsertInLibraryArgs {
  object: IBoardGamesUserInsertInput;
  on_conflict?: InputMaybe<IBoardGamesUserOnConflict>;
}


/** mutation root */
export interface IMutationRootInsertBoardGamesUserArgs {
  objects: Array<IBoardGamesUserInsertInput>;
  on_conflict?: InputMaybe<IBoardGamesUserOnConflict>;
}


/** mutation root */
export interface IMutationRootUpdateBoardGamesUserArgs {
  _inc?: InputMaybe<IBoardGamesUserIncInput>;
  _set?: InputMaybe<IBoardGamesUserSetInput>;
  where: IBoardGamesUserBoolExp;
}


/** mutation root */
export interface IMutationRootUpdateBoardGamesUserByPkArgs {
  _inc?: InputMaybe<IBoardGamesUserIncInput>;
  _set?: InputMaybe<IBoardGamesUserSetInput>;
  pk_columns: IBoardGamesUserPkColumnsInput;
}


/** mutation root */
export interface IMutationRootUpdateBoardGamesUserManyArgs {
  updates: Array<IBoardGamesUserUpdates>;
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface INumericComparisonExp {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
}

/** column ordering options */
export enum IOrderBy {
  /** in ascending order, nulls last */
  ASC = 'asc',
  /** in ascending order, nulls first */
  ASC_NULLS_FIRST = 'asc_nulls_first',
  /** in ascending order, nulls last */
  ASC_NULLS_LAST = 'asc_nulls_last',
  /** in descending order, nulls first */
  DESC = 'desc',
  /** in descending order, nulls first */
  DESC_NULLS_FIRST = 'desc_nulls_first',
  /** in descending order, nulls last */
  DESC_NULLS_LAST = 'desc_nulls_last'
}

export interface IQueryRoot {
  __typename?: 'query_root';
  /** fetch data from the table: "always_true_view" */
  always_true_view: Array<IAlwaysTrueView>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<IAuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<IAuthRefreshTokens>;
  /** fetch data from the table: "board_games" using primary key columns */
  boardGame?: Maybe<IBoardGames>;
  /** fetch data from the table: "board_games" */
  boardGames: Array<IBoardGames>;
  /** fetch data from the table: "board_games_categories" */
  board_games_categories: Array<IBoardGamesCategories>;
  /** fetch data from the table: "board_games_mechanics" */
  board_games_mechanics: Array<IBoardGamesMechanics>;
  /** fetch data from the table: "categories" */
  categories: Array<ICategories>;
  /** execute function "get_mechanic_percentages_for_user" which returns "mechanic_percentage" */
  getMechanicPercentagesForUser: Array<IMechanicPercentage>;
  /** fetch data from the table: "board_games_user" */
  library: Array<IBoardGamesUser>;
  lookupGame?: Maybe<ILookupResults>;
  /** fetch data from the table: "mechanic_percentage" */
  mechanic_percentage: Array<IMechanicPercentage>;
  /** fetch data from the table: "mechanics" */
  mechanics: Array<IMechanics>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<IUsers>;
  /** fetch data from the table: "auth.users" */
  users: Array<IUsers>;
}


export interface IQueryRootAlwaysTrueViewArgs {
  distinct_on?: InputMaybe<Array<IAlwaysTrueViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAlwaysTrueViewOrderBy>>;
  where?: InputMaybe<IAlwaysTrueViewBoolExp>;
}


export interface IQueryRootAuthRefreshTokenArgs {
  id: Scalars['uuid']['input'];
}


export interface IQueryRootAuthRefreshTokensArgs {
  distinct_on?: InputMaybe<Array<IAuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAuthRefreshTokensOrderBy>>;
  where?: InputMaybe<IAuthRefreshTokensBoolExp>;
}


export interface IQueryRootBoardGameArgs {
  uuid: Scalars['uuid']['input'];
}


export interface IQueryRootBoardGamesArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesOrderBy>>;
  where?: InputMaybe<IBoardGamesBoolExp>;
}


export interface IQueryRootBoardGamesCategoriesArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesCategoriesOrderBy>>;
  where?: InputMaybe<IBoardGamesCategoriesBoolExp>;
}


export interface IQueryRootBoardGamesMechanicsArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesMechanicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesMechanicsOrderBy>>;
  where?: InputMaybe<IBoardGamesMechanicsBoolExp>;
}


export interface IQueryRootCategoriesArgs {
  distinct_on?: InputMaybe<Array<ICategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICategoriesOrderBy>>;
  where?: InputMaybe<ICategoriesBoolExp>;
}


export interface IQueryRootGetMechanicPercentagesForUserArgs {
  args: IGetMechanicPercentagesForUserArgs;
  distinct_on?: InputMaybe<Array<IMechanicPercentageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMechanicPercentageOrderBy>>;
  where?: InputMaybe<IMechanicPercentageBoolExp>;
}


export interface IQueryRootLibraryArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesUserOrderBy>>;
  where?: InputMaybe<IBoardGamesUserBoolExp>;
}


export interface IQueryRootLookupGameArgs {
  barcode?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}


export interface IQueryRootMechanicPercentageArgs {
  distinct_on?: InputMaybe<Array<IMechanicPercentageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMechanicPercentageOrderBy>>;
  where?: InputMaybe<IMechanicPercentageBoolExp>;
}


export interface IQueryRootMechanicsArgs {
  distinct_on?: InputMaybe<Array<IMechanicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMechanicsOrderBy>>;
  where?: InputMaybe<IMechanicsBoolExp>;
}


export interface IQueryRootUserArgs {
  id: Scalars['uuid']['input'];
}


export interface IQueryRootUsersArgs {
  distinct_on?: InputMaybe<Array<IUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUsersOrderBy>>;
  where?: InputMaybe<IUsersBoolExp>;
}

export interface ISubscriptionRoot {
  __typename?: 'subscription_root';
  /** fetch data from the table: "always_true_view" */
  always_true_view: Array<IAlwaysTrueView>;
  /** fetch data from the table in a streaming manner: "always_true_view" */
  always_true_view_stream: Array<IAlwaysTrueView>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<IAuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<IAuthRefreshTokens>;
  /** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
  authRefreshTokens_stream: Array<IAuthRefreshTokens>;
  /** fetch data from the table: "board_games" using primary key columns */
  boardGame?: Maybe<IBoardGames>;
  /** fetch data from the table: "board_games" */
  boardGames: Array<IBoardGames>;
  /** fetch data from the table in a streaming manner: "board_games" */
  boardGamesStream: Array<IBoardGames>;
  /** fetch data from the table: "board_games_categories" */
  board_games_categories: Array<IBoardGamesCategories>;
  /** fetch data from the table in a streaming manner: "board_games_categories" */
  board_games_categories_stream: Array<IBoardGamesCategories>;
  /** fetch data from the table: "board_games_mechanics" */
  board_games_mechanics: Array<IBoardGamesMechanics>;
  /** fetch data from the table in a streaming manner: "board_games_mechanics" */
  board_games_mechanics_stream: Array<IBoardGamesMechanics>;
  /** fetch data from the table: "categories" */
  categories: Array<ICategories>;
  /** fetch data from the table in a streaming manner: "categories" */
  categories_stream: Array<ICategories>;
  /** execute function "get_mechanic_percentages_for_user" which returns "mechanic_percentage" */
  getMechanicPercentagesForUser: Array<IMechanicPercentage>;
  /** fetch data from the table: "board_games_user" */
  library: Array<IBoardGamesUser>;
  /** fetch data from the table: "mechanic_percentage" */
  mechanic_percentage: Array<IMechanicPercentage>;
  /** fetch data from the table in a streaming manner: "mechanic_percentage" */
  mechanic_percentage_stream: Array<IMechanicPercentage>;
  /** fetch data from the table: "mechanics" */
  mechanics: Array<IMechanics>;
  /** fetch data from the table in a streaming manner: "mechanics" */
  mechanics_stream: Array<IMechanics>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<IUsers>;
  /** fetch data from the table: "auth.users" */
  users: Array<IUsers>;
  /** fetch data from the table in a streaming manner: "auth.users" */
  users_stream: Array<IUsers>;
}


export interface ISubscriptionRootAlwaysTrueViewArgs {
  distinct_on?: InputMaybe<Array<IAlwaysTrueViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAlwaysTrueViewOrderBy>>;
  where?: InputMaybe<IAlwaysTrueViewBoolExp>;
}


export interface ISubscriptionRootAlwaysTrueViewStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAlwaysTrueViewStreamCursorInput>>;
  where?: InputMaybe<IAlwaysTrueViewBoolExp>;
}


export interface ISubscriptionRootAuthRefreshTokenArgs {
  id: Scalars['uuid']['input'];
}


export interface ISubscriptionRootAuthRefreshTokensArgs {
  distinct_on?: InputMaybe<Array<IAuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAuthRefreshTokensOrderBy>>;
  where?: InputMaybe<IAuthRefreshTokensBoolExp>;
}


export interface ISubscriptionRootAuthRefreshTokensStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IAuthRefreshTokensStreamCursorInput>>;
  where?: InputMaybe<IAuthRefreshTokensBoolExp>;
}


export interface ISubscriptionRootBoardGameArgs {
  uuid: Scalars['uuid']['input'];
}


export interface ISubscriptionRootBoardGamesArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesOrderBy>>;
  where?: InputMaybe<IBoardGamesBoolExp>;
}


export interface ISubscriptionRootBoardGamesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IBoardGamesStreamCursorInput>>;
  where?: InputMaybe<IBoardGamesBoolExp>;
}


export interface ISubscriptionRootBoardGamesCategoriesArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesCategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesCategoriesOrderBy>>;
  where?: InputMaybe<IBoardGamesCategoriesBoolExp>;
}


export interface ISubscriptionRootBoardGamesCategoriesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IBoardGamesCategoriesStreamCursorInput>>;
  where?: InputMaybe<IBoardGamesCategoriesBoolExp>;
}


export interface ISubscriptionRootBoardGamesMechanicsArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesMechanicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesMechanicsOrderBy>>;
  where?: InputMaybe<IBoardGamesMechanicsBoolExp>;
}


export interface ISubscriptionRootBoardGamesMechanicsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IBoardGamesMechanicsStreamCursorInput>>;
  where?: InputMaybe<IBoardGamesMechanicsBoolExp>;
}


export interface ISubscriptionRootCategoriesArgs {
  distinct_on?: InputMaybe<Array<ICategoriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ICategoriesOrderBy>>;
  where?: InputMaybe<ICategoriesBoolExp>;
}


export interface ISubscriptionRootCategoriesStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ICategoriesStreamCursorInput>>;
  where?: InputMaybe<ICategoriesBoolExp>;
}


export interface ISubscriptionRootGetMechanicPercentagesForUserArgs {
  args: IGetMechanicPercentagesForUserArgs;
  distinct_on?: InputMaybe<Array<IMechanicPercentageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMechanicPercentageOrderBy>>;
  where?: InputMaybe<IMechanicPercentageBoolExp>;
}


export interface ISubscriptionRootLibraryArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesUserOrderBy>>;
  where?: InputMaybe<IBoardGamesUserBoolExp>;
}


export interface ISubscriptionRootMechanicPercentageArgs {
  distinct_on?: InputMaybe<Array<IMechanicPercentageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMechanicPercentageOrderBy>>;
  where?: InputMaybe<IMechanicPercentageBoolExp>;
}


export interface ISubscriptionRootMechanicPercentageStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IMechanicPercentageStreamCursorInput>>;
  where?: InputMaybe<IMechanicPercentageBoolExp>;
}


export interface ISubscriptionRootMechanicsArgs {
  distinct_on?: InputMaybe<Array<IMechanicsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IMechanicsOrderBy>>;
  where?: InputMaybe<IMechanicsBoolExp>;
}


export interface ISubscriptionRootMechanicsStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IMechanicsStreamCursorInput>>;
  where?: InputMaybe<IMechanicsBoolExp>;
}


export interface ISubscriptionRootUserArgs {
  id: Scalars['uuid']['input'];
}


export interface ISubscriptionRootUsersArgs {
  distinct_on?: InputMaybe<Array<IUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IUsersOrderBy>>;
  where?: InputMaybe<IUsersBoolExp>;
}


export interface ISubscriptionRootUsersStreamArgs {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IUsersStreamCursorInput>>;
  where?: InputMaybe<IUsersBoolExp>;
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface ITimestamptzComparisonExp {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
}

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface IUsers {
  __typename?: 'users';
  avatarUrl: Scalars['String']['output'];
  /** An array relationship */
  boardGames: Array<IBoardGamesUser>;
  /** An aggregate relationship */
  boardGames_aggregate: IBoardGamesUserAggregate;
  createdAt: Scalars['timestamptz']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  refreshTokens: Array<IAuthRefreshTokens>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface IUsersBoardGamesArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesUserOrderBy>>;
  where?: InputMaybe<IBoardGamesUserBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface IUsersBoardGamesAggregateArgs {
  distinct_on?: InputMaybe<Array<IBoardGamesUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IBoardGamesUserOrderBy>>;
  where?: InputMaybe<IBoardGamesUserBoolExp>;
}


/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export interface IUsersRefreshTokensArgs {
  distinct_on?: InputMaybe<Array<IAuthRefreshTokensSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IAuthRefreshTokensOrderBy>>;
  where?: InputMaybe<IAuthRefreshTokensBoolExp>;
}

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export interface IUsersBoolExp {
  _and?: InputMaybe<Array<IUsersBoolExp>>;
  _not?: InputMaybe<IUsersBoolExp>;
  _or?: InputMaybe<Array<IUsersBoolExp>>;
  avatarUrl?: InputMaybe<IStringComparisonExp>;
  boardGames?: InputMaybe<IBoardGamesUserBoolExp>;
  boardGames_aggregate?: InputMaybe<IBoardGamesUserAggregateBoolExp>;
  createdAt?: InputMaybe<ITimestamptzComparisonExp>;
  displayName?: InputMaybe<IStringComparisonExp>;
  id?: InputMaybe<IUuidComparisonExp>;
  refreshTokens?: InputMaybe<IAuthRefreshTokensBoolExp>;
}

/** Ordering options when selecting data from "auth.users". */
export interface IUsersOrderBy {
  avatarUrl?: InputMaybe<IOrderBy>;
  boardGames_aggregate?: InputMaybe<IBoardGamesUserAggregateOrderBy>;
  createdAt?: InputMaybe<IOrderBy>;
  displayName?: InputMaybe<IOrderBy>;
  id?: InputMaybe<IOrderBy>;
  refreshTokens_aggregate?: InputMaybe<IAuthRefreshTokensAggregateOrderBy>;
}

/** select columns of table "auth.users" */
export enum IUsersSelectColumn {
  /** column name */
  AVATARURL = 'avatarUrl',
  /** column name */
  CREATEDAT = 'createdAt',
  /** column name */
  DISPLAYNAME = 'displayName',
  /** column name */
  ID = 'id'
}

/** Streaming cursor of the table "users" */
export interface IUsersStreamCursorInput {
  /** Stream column input with initial value */
  initial_value: IUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<ICursorOrdering>;
}

/** Initial value of the column from where the streaming should start */
export interface IUsersStreamCursorValueInput {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface IUuidComparisonExp {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
}

export type IAddBoardGameMutationVariables = Exact<{
  bggId: Scalars['Int']['input'];
}>;


export type IAddBoardGameMutation = (
  { __typename?: 'mutation_root' }
  & { addGame?: Maybe<(
    { __typename?: 'AddGameOutput' }
    & Pick<IAddGameOutput, 'uuid' | 'boardGameUuid'>
  )> }
);

export type IGetBoardGameQueryVariables = Exact<{
  uuid: Scalars['uuid']['input'];
}>;


export type IGetBoardGameQuery = (
  { __typename?: 'query_root' }
  & { boardGame?: Maybe<(
    { __typename?: 'board_games' }
    & Pick<IBoardGames, 'description' | 'summary' | 'imageUrl' | 'cloudinaryPublicId' | 'maxAge' | 'maxPlayers' | 'minPlayers' | 'minAge' | 'nonRecommendedPlayerCount' | 'playTimeInMinutes' | 'recommendedPlayerCount' | 'thumbnailUrl' | 'title' | 'yearPublished' | 'uuid'>
    & { mechanics: Array<(
      { __typename?: 'board_games_mechanics' }
      & { mechanic: (
        { __typename?: 'mechanics' }
        & Pick<IMechanics, 'value'>
      ) }
    )>, categories: Array<(
      { __typename?: 'board_games_categories' }
      & { category?: Maybe<(
        { __typename?: 'categories' }
        & Pick<ICategories, 'value'>
      )> }
    )>, owners: Array<(
      { __typename?: 'board_games_user' }
      & Pick<IBoardGamesUser, 'uuid' | 'rating'>
    )> }
  )>, user?: Maybe<(
    { __typename?: 'users' }
    & Pick<IUsers, 'id' | 'avatarUrl' | 'displayName'>
  )> }
);

export type IGetBoardGamesQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<IBoardGamesOrderBy> | IBoardGamesOrderBy>;
  _ilike?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['uuid']['input'];
}>;


export type IGetBoardGamesQuery = (
  { __typename?: 'query_root' }
  & { boardGames: Array<(
    { __typename?: 'board_games' }
    & Pick<IBoardGames, 'thumbnailUrl' | 'cloudinaryPublicId' | 'title' | 'uuid'>
    & { owners: Array<(
      { __typename?: 'board_games_user' }
      & Pick<IBoardGamesUser, 'uuid' | 'rating'>
    )> }
  )>, user?: Maybe<(
    { __typename?: 'users' }
    & Pick<IUsers, 'id' | 'avatarUrl' | 'displayName'>
  )> }
);

export type IGetMechanicPercentagesForUserQueryVariables = Exact<{
  percentageThreshold?: InputMaybe<Scalars['numeric']['input']>;
  userUuid: Scalars['uuid']['input'];
}>;


export type IGetMechanicPercentagesForUserQuery = (
  { __typename?: 'query_root' }
  & { getMechanicPercentagesForUser: Array<(
    { __typename?: 'mechanic_percentage' }
    & Pick<IMechanicPercentage, 'count' | 'mechanic' | 'percentage'>
  )> }
);

export type IGetUsersWithBoardGamesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUsersWithBoardGamesQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<IUsers, 'createdAt' | 'displayName' | 'id' | 'avatarUrl'>
    & { boardGames_aggregate: (
      { __typename?: 'board_games_user_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'board_games_user_aggregate_fields' }
        & Pick<IBoardGamesUserAggregateFields, 'count'>
      )> }
    ) }
  )> }
);

export type ILookupGameQueryVariables = Exact<{
  barcode?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}>;


export type ILookupGameQuery = (
  { __typename?: 'query_root' }
  & { lookupGame?: Maybe<(
    { __typename?: 'LookupResults' }
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'LookupResult' }
      & Pick<ILookupResult, 'id' | 'name' | 'thumbnail_url'>
    )>>> }
  )> }
);



export const AddBoardGameDocument = `
    mutation addBoardGame($bggId: Int!) {
  addGame(bggId: $bggId) {
    uuid
    boardGameUuid
  }
}
    `;

export const useAddBoardGameMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<IAddBoardGameMutation, TError, IAddBoardGameMutationVariables, TContext>) => {
    
    return useMutation<IAddBoardGameMutation, TError, IAddBoardGameMutationVariables, TContext>(
      {
    mutationKey: ['addBoardGame'],
    mutationFn: (variables?: IAddBoardGameMutationVariables) => fetcher<IAddBoardGameMutation, IAddBoardGameMutationVariables>(AddBoardGameDocument, variables)(),
    ...options
  }
    )};

export const GetBoardGameDocument = `
    query getBoardGame($uuid: uuid!) {
  boardGame(uuid: $uuid) {
    description
    summary
    imageUrl
    cloudinaryPublicId
    maxAge
    maxPlayers
    minPlayers
    minAge
    nonRecommendedPlayerCount
    playTimeInMinutes
    recommendedPlayerCount
    thumbnailUrl
    title
    yearPublished
    mechanics {
      mechanic {
        value
      }
    }
    categories {
      category {
        value
      }
    }
    uuid
    owners {
      uuid
      rating
    }
  }
  user(id: $uuid) {
    id
    avatarUrl
    displayName
  }
}
    `;

export const useGetBoardGameQuery = <
      TData = IGetBoardGameQuery,
      TError = unknown
    >(
      variables: IGetBoardGameQueryVariables,
      options?: Omit<UseQueryOptions<IGetBoardGameQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<IGetBoardGameQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<IGetBoardGameQuery, TError, TData>(
      {
    queryKey: ['getBoardGame', variables],
    queryFn: fetcher<IGetBoardGameQuery, IGetBoardGameQueryVariables>(GetBoardGameDocument, variables),
    ...options
  }
    )};

export const GetBoardGamesDocument = `
    query getBoardGames($orderBy: [board_games_order_by!] = {title: asc}, $_ilike: String = "%%", $uuid: uuid!) {
  boardGames(
    where: {owners: {userUuid: {_eq: $uuid}}, title: {_ilike: $_ilike}}
    order_by: $orderBy
  ) {
    thumbnailUrl
    cloudinaryPublicId
    title
    uuid
    owners {
      uuid
      rating
    }
  }
  user(id: $uuid) {
    id
    avatarUrl
    displayName
  }
}
    `;

export const useGetBoardGamesQuery = <
      TData = IGetBoardGamesQuery,
      TError = unknown
    >(
      variables: IGetBoardGamesQueryVariables,
      options?: Omit<UseQueryOptions<IGetBoardGamesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<IGetBoardGamesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<IGetBoardGamesQuery, TError, TData>(
      {
    queryKey: ['getBoardGames', variables],
    queryFn: fetcher<IGetBoardGamesQuery, IGetBoardGamesQueryVariables>(GetBoardGamesDocument, variables),
    ...options
  }
    )};

export const GetMechanicPercentagesForUserDocument = `
    query getMechanicPercentagesForUser($percentageThreshold: numeric = 5, $userUuid: uuid!) {
  getMechanicPercentagesForUser(
    args: {percentagethreshold: $percentageThreshold, useruuid: $userUuid}
  ) {
    count
    mechanic
    percentage
  }
}
    `;

export const useGetMechanicPercentagesForUserQuery = <
      TData = IGetMechanicPercentagesForUserQuery,
      TError = unknown
    >(
      variables: IGetMechanicPercentagesForUserQueryVariables,
      options?: Omit<UseQueryOptions<IGetMechanicPercentagesForUserQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<IGetMechanicPercentagesForUserQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<IGetMechanicPercentagesForUserQuery, TError, TData>(
      {
    queryKey: ['getMechanicPercentagesForUser', variables],
    queryFn: fetcher<IGetMechanicPercentagesForUserQuery, IGetMechanicPercentagesForUserQueryVariables>(GetMechanicPercentagesForUserDocument, variables),
    ...options
  }
    )};

export const GetUsersWithBoardGamesDocument = `
    query getUsersWithBoardGames {
  users(
    where: {boardGames_aggregate: {count: {predicate: {_gte: 1}}}}
    order_by: {displayName: asc}
  ) {
    createdAt
    displayName
    id
    avatarUrl
    boardGames_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

export const useGetUsersWithBoardGamesQuery = <
      TData = IGetUsersWithBoardGamesQuery,
      TError = unknown
    >(
      variables?: IGetUsersWithBoardGamesQueryVariables,
      options?: Omit<UseQueryOptions<IGetUsersWithBoardGamesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<IGetUsersWithBoardGamesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<IGetUsersWithBoardGamesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['getUsersWithBoardGames'] : ['getUsersWithBoardGames', variables],
    queryFn: fetcher<IGetUsersWithBoardGamesQuery, IGetUsersWithBoardGamesQueryVariables>(GetUsersWithBoardGamesDocument, variables),
    ...options
  }
    )};

export const LookupGameDocument = `
    query lookupGame($barcode: String, $title: String) {
  lookupGame(barcode: $barcode, title: $title) {
    results {
      id
      name
      thumbnail_url
    }
  }
}
    `;

export const useLookupGameQuery = <
      TData = ILookupGameQuery,
      TError = unknown
    >(
      variables?: ILookupGameQueryVariables,
      options?: Omit<UseQueryOptions<ILookupGameQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ILookupGameQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ILookupGameQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['lookupGame'] : ['lookupGame', variables],
    queryFn: fetcher<ILookupGameQuery, ILookupGameQueryVariables>(LookupGameDocument, variables),
    ...options
  }
    )};

import {
  Button,
  CloseButton,
  Modal,
  TextInput,
  UnstyledButton,
} from "@mantine/core"
import { useClickOutside, useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { useUserData } from "@nhost/react"
import { IconSearch, IconX } from "@tabler/icons-react"
import { AnimatePresence, motion } from "framer-motion"
import { type FC, useRef, useState } from "react"
import { trackWindowScroll } from "react-lazy-load-image-component"
import { BoardGamePreview } from "#components/BoardGamePreview"
import { EmptyLibrary } from "#components/Library/EmptyLibrary"
import { Search } from "#components/Search"
import { Shelf } from "#components/shelf"
import { BoardGameDetails } from "#containers/BoardGameDetails"
import { ScreenSizes, useScreenSize } from "#contexts/ScreenSizeContext"
import {
  type IGetBoardGamesQuery,
  type Maybe,
  useAddBoardGameMutation,
} from "#graphql/graphql"
import type { ILibrary } from "./interfaces"
import Styles from "./styles.module.scss"

const Library: FC<ILibrary> = ({ data, refetch, scrollPosition }) => {
  const { isMobile, screenSize } = useScreenSize()
  const user = useUserData()
  const searchTextReference = useRef<HTMLInputElement>(null)
  const [searchText, setSearchText] = useState("")
  const [opened, { close, open }] = useDisclosure(false)
  const [boardGameDetails, setBoardGameDetails] = useState<
    IGetBoardGamesQuery["boardGames"][0] | null
  >(null)
  const clickOutside = useClickOutside(() => setBoardGameDetails(null))
  const closeModal = () => {
    setBoardGameDetails(null)
  }
  const { isPending, mutate } = useAddBoardGameMutation()

  const AddGameModal = () => {
    return (
      <Modal
        centered={true}
        fullScreen={isMobile}
        onClose={close}
        opened={opened}
        size={"auto"}
        title={"Add board game"}
      >
        <motion.div>
          <Search mutationLoading={isPending} onAdd={handleAdd} />
        </motion.div>
      </Modal>
    )
  }

  const handleAdd = async (id: Maybe<number> | undefined) => {
    if (!id) {
      return null
    }

    mutate(
      { bggId: id },
      {
        onSuccess: (mutationData) => {
          if (refetch) {
            refetch()
          }

          close()
          if (!mutationData) {
            return
          }

          const element = document.querySelector(
            `[data-id="${mutationData?.addGame?.boardGameUuid}"]`,
          )
          if (!element) {
            return
          }

          notifications.show({
            message: "The game has been added to your library ",
            title: "Game Added",
          })

          return element.scrollIntoView({ behavior: "smooth" })
        },
      },
    )
  }

  const handleOnClick = (boardGame: IGetBoardGamesQuery["boardGames"][0]) => {
    setBoardGameDetails(boardGame)
  }

  const itemHeights: Record<ScreenSizes, number> = {
    [ScreenSizes.LG]: 140,
    [ScreenSizes.MD]: 150,
    [ScreenSizes.SM]: 125,
    [ScreenSizes.XL]: 150,
    [ScreenSizes.XS]: 75,
  }

  const filteredGames = data?.boardGames?.filter(({ title }) =>
    title.toLowerCase().includes(searchText.toLowerCase().trim()),
  )

  const hasGames = filteredGames && filteredGames.length > 0
  const isOwner = user && user.id === data?.user?.id
  if (!(hasGames || searchText)) {
    return (
      <>
        <EmptyLibrary onAddGame={open} isOwner={!!isOwner} />
        <AddGameModal />
      </>
    )
  }

  const items = filteredGames?.map((boardGame) => (
    <BoardGamePreview
      data={boardGame}
      itemHeight={itemHeights[screenSize]}
      key={boardGame.uuid}
      onClick={handleOnClick}
      scrollPosition={scrollPosition}
      selected={boardGameDetails?.uuid === boardGame.uuid}
    />
  ))

  return (
    <div className={Styles.libraryContainer}>
      <div className={Styles.actions}>
        <TextInput
          className={Styles.search}
          leftSection={
            <IconSearch stroke={1.5} style={{ height: 12, width: 12 }} />
          }
          mb="sm"
          onChange={(event) => setSearchText(event.target.value)}
          placeholder="Search"
          ref={searchTextReference}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => {
                setSearchText("")
                if (searchTextReference.current) {
                  searchTextReference.current.value = ""
                }
              }}
              style={{ display: searchText ? undefined : "none" }}
            />
          }
          size="xs"
        />
        {isOwner && (
          <Button className={Styles.addButton} onClick={open} size={"xs"}>
            {screenSize === ScreenSizes.XS ? "Add" : "Add board game"}
          </Button>
        )}
      </div>
      <Shelf itemHeight={itemHeights[screenSize]} items={items} />
      <AnimatePresence>
        {boardGameDetails && (
          <div className={Styles.modal} id={"BoardGamesDetailModal"}>
            <motion.div
              animate={{ opacity: 1 }}
              className={Styles.modalOverlay}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            />
            <motion.div
              className={Styles.modalContent}
              layoutId={boardGameDetails.uuid}
              onClick={(clickEvent) => clickEvent.preventDefault()}
              ref={clickOutside}
              transition={{
                damping: 30,
                duration: 0.2,
                stiffness: 500,
                type: "spring",
              }}
            >
              <UnstyledButton
                className={Styles.closeModal}
                onClick={closeModal}
              >
                <IconX />
              </UnstyledButton>
              <BoardGameDetails
                thumbnailUrl={boardGameDetails.thumbnailUrl}
                cloudinaryPublicId={boardGameDetails.cloudinaryPublicId}
                uuid={boardGameDetails.uuid}
              />
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      <AddGameModal />
    </div>
  )
}

export const LibraryWithScrollTracking = trackWindowScroll(Library)
